import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
//import Img from 'gatsby-image'
import { Box, Text, Card, Flex } from "theme-ui";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Section from "@components/Section";
import Seo from "@widgets/Seo";
import CodeBlock from "@tenon-io/tenon-codeblock";

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`,
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4,
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    " > div + div": {
      ml: [0, 0, 5],
    },
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`],
  },
};

export default (props) => {
  const data = useStaticQuery(installQuery);
  const image = getImage(data.photo);

  //const { photo } = data
  //const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <Layout {...props}>
      <Seo title="Install Swift" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header="How to install Swift on Arm Powered Devices" />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
          </Box>
          <Divider />
          <Section title="The Swift Community Apt Repository">
            <Text variant="p">
              For more details about the new Swift Community Apt Repository go
              to <a href="https://swiftlang.xyz/">https://swiftlang.xyz</a>
            </Text>
            <h3>Install Instructions for Debian and Ubuntu based distro's</h3>
            <Card variant="paper">
              <h4>Step 1 - System update and install curl</h4>
              <CodeBlock
                codeString={"sudo apt update && sudo apt upgrade"}
                language="bash"
              />
              <CodeBlock codeString={"sudo apt install curl"} language="bash" />
              <h4>Step 2 - Run the quick install script</h4>
              <CodeBlock
                codeString={
                  "curl -s https://archive.swiftlang.xyz/install.sh | sudo bash"
                }
                language="bash"
              />
              <h4>Step 3 - Choose which version of Swift to install</h4>
              <Text variant="p">
                If your system is compatible you will be presented with a menu
                which will allow you to choose which version of swift is
                installed.
                <br />
                <br />
                <b>1)</b> (main) latest - This will update to the latest release
                of Swift available
                <br />
                <b>2)</b> Swift version 5.4.* - this will update to the latest
                point release of Swift 5.4
                <br />
                <b>3)</b> Swift version 5.5.* - this will update to the latest
                point release of Swift 5.5
                <br />
                <b>4)</b> Swift version 5.6.* - this will update to the latest
                point release of Swift 5.6
                <br />
                <br />
                <i>
                  NOTE 1: The number of different versions of swift available
                  will differ depending on the distribution/version.
                  <br />
                  NOTE 2: If installing on Raspberry Pi OS/Raspbian arm6/7
                  32bit, the only swift version available at the moment is
                  swift-5.1.5 so there will be no menu presented
                </i>
              </Text>
              <h4>Step 4 - Install Swift</h4>
              <CodeBlock
                codeString={"sudo apt install swiftlang"}
                language="bash"
              />
            </Card>
          </Section>
          <Divider />
          <a href="https://packagecloud.io/">
            <img
              height="46"
              width="158"
              alt="Private NPM registry and Maven, RPM, DEB, PyPi and RubyGem Repository · packagecloud"
              src="https://packagecloud.io/images/packagecloud-badge.png"
            />
          </a>
          <Section title="Quick Start using the Swift-Arm PackageCloud Repository">
            <h3>For Debian based distro's</h3>
            <Card variant="paper">
              <Text variant="p">
                <i>
                  Install the <b>Swift-Arm</b> package repository.
                </i>
              </Text>
              <CodeBlock
                codeString={
                  "curl -s https://packagecloud.io/install/repositories/swift-arm/release/script.deb.sh | sudo bash"
                }
                language="css"
              />
              <Text variant="p">
                <i>
                  Install <b>Swift</b>.
                </i>
              </Text>
              <CodeBlock
                codeString={"sudo apt install swiftlang"}
                language="css"
              />
            </Card>
          </Section>
          <Divider />
          <Section title="Compatible Systems and Distro's" />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <h3>Debian based Linux Distributions</h3>
                <Card variant="paper">
                  <ul>
                    <i>
                      Available from the <b>Swift-Arm</b> package repository
                    </i>
                    <li>Debian 10 Buster</li>
                    <li>Debian 11 Bullseye</li>
                    <li>Ubuntu 18.04 / 20.04 / 22.04</li>
                    <br />
                    <li>Raspberry Pi OS / Raspbian Buster (32 bit)</li>
                    <li>Raspberry Pi OS / Raspbian Bullseye (64 bit)</li>
                  </ul>
                </Card>
              </Section>
            </Box>
            <Box sx={styles.column}>
              {/* <Section>
              <h3>RedHat based Linux Distributions</h3>
                <Card variant='paper'>
                  <ul>
                    <i>Available from <b>Swift-Arm</b> the package repository</i>
                    <li>Amazon Linux 2</li>
                    <li>CentOS 8</li>
                    <br/>
                    <i>Available from the <b>Fedora</b> package repository</i>
                    <li>Fedora</li>
                  </ul>
                </Card>
              </Section> */}
            </Box>
          </Flex>
          <Divider />
          <Section title="Other Systems and Special Cases" />
          <Box sx={styles.column}>
            <Section>
              {/* <h3>Ubuntu 20.10 Groovy Gorilla</h3>
                <Card variant='paper'>
                  <Text>
                    Ubuntu 20.10 Groovy Gorilla is not yet available in the PackageCloud repository.
                    <br/>
                    To install <b>Swift</b> on Ubuntu 20.10 Groovy Gorilla you will need to download the .deb installation file for Ubuntu 20.04 Focal.
                    <CodeBlock codeString={"wget --content-disposition https://packagecloud.io/swift-arm/release/packages/ubuntu/focal/swiftlang_5.3.2-4-ubuntu-focal_arm64.deb/download.deb"} language="css"/>
                    Then use following command to install <b>Swift</b>.
                    <CodeBlock codeString={"sudo apt install ./swiftlang_5.3.2-4-ubuntu-focal_arm64.deb"} language="css"/>
                  </Text>
                </Card> */}
              <h3>
                Other Distro's based on Debian and Ubuntu such as Mint,
                Elementary OS, Lubuntu, Deepin, Kali Linux etc
              </h3>
              <Card variant="paper">
                <Text>
                  If your distro is based on the latest Debian or Ubuntu
                  versions the install script fails to find a <b>Swift</b>{" "}
                  package,
                  <CodeBlock
                    codeString={"E: Unable to locate package"}
                    language="css"
                  />
                  you can manually install <b>Swift</b> by downloading the
                  equivalent Debian or Ubuntu package closest to the version of
                  your OS.
                  <br />
                  <br />
                  For example if you are using <b>Linux Mint 20.1</b> which is
                  based on <b>Ubuntu Focal</b>
                  <br />
                  You will need to download the .deb installation package for
                  Ubuntu 20.04 Focal.
                  <br />
                  The wget download link is available on the package release
                  page.
                  <br />
                  <i>
                    https://packagecloud.io/swift-arm/release/packages/ubuntu/focal/swiftlang_5.3.2-4-ubuntu-focal_arm64.deb
                  </i>
                  <br />
                  <CodeBlock
                    codeString={
                      "wget --content-disposition https://packagecloud.io/swift-arm/release/packages/ubuntu/focal/swiftlang_5.3.2-4-ubuntu-focal_arm64.deb/download.deb"
                    }
                    language="css"
                  />
                  Then use following command to install <b>Swift</b>.
                  <CodeBlock
                    codeString={
                      "sudo apt install ./swiftlang_5.3.2-4-ubuntu-focal_arm64.deb"
                    }
                    language="css"
                  />
                </Text>
              </Card>
              {/* <h3>Raspberry Pi OS and Raspbian Buster 32bit versions</h3>
                <Card variant='paper'>
                  <Text>
                    The official OS for the Raspberry Pi is still 32bit and will restrict the version of <b>Swift</b> that you can install.
                    <br/>
                    The latest version of <b>Swift</b> available for 32bit arm is 5.1.5.
                    <br/>
                    If you need the latest version of <b>Swift</b> you will need to install a 64bit OS. 
                    <br/>
                    Ubuntu 20.10, 21.04 and Raspbian 64bit Beta are available.
                  </Text>
                </Card> */}
            </Section>
          </Box>
        </Main>
      </Stack>
    </Layout>
  );
};

const installQuery = graphql`
  query installQuery {
    photo: file(absolutePath: { regex: "/rpi4big.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 650
          height: 390
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`;
